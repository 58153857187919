import { IDashboard } from '@/pages/Dashboard/interface';
import {
  getWorkbenchCustomerInfo,
  getWorkbenchAccountMediaInfo,
  getWorkbenchFinanceFundsInfo,
  getWorkbenchFacebookQualityInfo,
} from '@/services/workDashboard';
import { ResponseStructure } from '@/utils/request';
import { fetchErrorHandler, fetchSuccess } from '@/utils/utils';

export interface WorkDashboardModelType {
  namespace: 'workDashboard';
  state: null;
  effects: {
    /** 获取用户总体数据 */
    getWorkbenchCustomerInfo: Effect;
    /** 获取用户账号管理媒体数据 */
    getWorkbenchAccountMediaInfo: Effect;
    /** 获取用户财务资产数据 */
    getWorkbenchFinanceFundsInfo: Effect;
    /** 获取FB账户质量数据  */
    getWorkbenchFacebookQualityInfo: Effect;
  };
}

const WorkDashboardModel: WorkDashboardModelType = {
  namespace: 'workDashboard',
  state: null,
  effects: {
    *getWorkbenchCustomerInfo({ payload, onSuccess }, { call }) {
      const res: ResponseStructure<IDashboard.Response.CustomerInfo> =
        yield call(getWorkbenchCustomerInfo, payload);

      if (fetchSuccess(res)) {
        onSuccess?.(res.data);

        return;
      }

      fetchErrorHandler(res);
    },
    *getWorkbenchAccountMediaInfo({ payload, onSuccess }, { call }) {
      const res: ResponseStructure<IDashboard.Response.AccountManagement> =
        yield call(getWorkbenchAccountMediaInfo, payload);

      if (fetchSuccess(res)) {
        onSuccess?.(res.data);

        return;
      }

      fetchErrorHandler(res);
    },
    *getWorkbenchFinanceFundsInfo({ payload, onSuccess }, { call }) {
      const res: ResponseStructure<IDashboard.Response.FinanceManagement> =
        yield call(getWorkbenchFinanceFundsInfo, payload);
      if (fetchSuccess(res)) {
        onSuccess?.(res.data);

        return;
      }

      fetchErrorHandler(res);
    },
    *getWorkbenchFacebookQualityInfo({ payload, onSuccess }, { call }) {
      const res: ResponseStructure<IDashboard.Response.FacebookQuality> =
        yield call(getWorkbenchFacebookQualityInfo, payload);
      if (fetchSuccess(res)) {
        onSuccess?.(res.data);

        return;
      }

      fetchErrorHandler(res);
    },
  },
};

export default WorkDashboardModel;
