import { getFlatMenus } from '@umijs/route-utils';
import { useEffect } from 'react';
import MenuCounter from './Counter';
import type { PrivateSiderMenuProps, SiderMenuProps } from './SiderMenu';
import SiderMenu from './SiderMenu';

const SiderMenuWrapper: React.FC<SiderMenuProps & PrivateSiderMenuProps> = (
  props,
) => {
  const { menuData, matchMenuKeys } = props;
  const { setFlatMenuKeys } = MenuCounter.useContainer();

  useEffect(() => {
    if (!menuData || menuData.length < 1) {
      return;
    }

    // 当 menu data 改变的时候重新计算这两个参数
    const newFlatMenus = getFlatMenus(menuData);
    setFlatMenuKeys(Object.keys(newFlatMenus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchMenuKeys.join('-')]);

  return <SiderMenu {...props} />;
};

export default SiderMenuWrapper;
