import request from '@/utils/request';
import type { ResponseStructure } from '@/utils/request';
import type { SettlementType } from '@/utils/enum';
import type { Password } from '@/components/RightContent/ModifyPassowrd';
import { API_USER, API_SMB } from '@/utils/utils';
import { RegisterParams, SmsCodeType } from '@/pages/User/interface';

/** 获取用户信息 */
export async function getUserInfo() {
  return request<ResponseStructure<API.UserInfo>>(
    `${API_SMB}/user/get_user_info`,
  );
}

/** 获取企业信息 */
export async function getBusinessInfo() {
  return request<ResponseStructure<API.BusinessInfo<SettlementType>>>(
    `${API_SMB}/user/get_business_info`,
  );
}

/** 修改企业信息 */
export async function updateBusinessInfo(data: Partial<API.BusinessInfo<SettlementType>>) {
  return request<ResponseStructure>(
    `${API_SMB}/user/update_business_info`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 登录 */
export async function login(data: { username: string; password: string; remember: 0 | 1 }) {
  return request<ResponseStructure>(`${API_USER}/login`, {
    method: 'POST',
    data: {
      ...data,
      // remember: 1,
    },
  });
}

/** 激活邮件重发 */
export async function resendActiveEmailApi(data: { username: string }) {
  return request<ResponseStructure>(`${API_USER}/active_email_resend`, {
    method: 'POST',
    data,
  });
}

/** 激活 */
export async function active(data: { email: string; password?: string; role: string; access_token: string; rid: string; }) {
  return request<ResponseStructure>(`${API_USER}/active`, {
    method: 'POST',
    data: {
      ...data,
      remember: 1,
    },
  });
}

/** 注册 */
export async function register(data: RegisterParams) {
  return request<ResponseStructure>(`${API_USER}/register`, {
    method: 'POST',
    data,
  });
}

/** 忘记密码 */
export async function forget(params: { username: string }) {
  return request<ResponseStructure>(`${API_USER}/reset_password`, {
    method: 'GET',
    params,
  });
}

/** 重置密码 */
export async function reset(data: {
  rid: string;
  token: string;
  password: string;
  re_password: string;
}) {
  return request<ResponseStructure>(`${API_USER}/reset_password`, {
    method: 'POST',
    data,
  });
}

/** 检查邮箱激活状态 */
export async function checkActive(data: { username: string }) {
  return request<ResponseStructure<{ is_active: 0 | 1 }>>(
    `${API_USER}/check_user_active`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 修改个人信息 */
export async function updateUserInfo(data: API.UserInfo) {
  return request<ResponseStructure<API.UserInfo>>(
    `${API_SMB}/user/update_user_info`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 修改密码 */
export async function modifyPassword(data: Password) {
  return request<ResponseStructure>(`${API_USER}/modify_password`, {
    method: 'POST',
    data,
  });
}

/** 退出登录 */
export async function logout() {
  return request<ResponseStructure>(`${API_USER}/logout`);
}

/** 获取当前企业用户资产监控提醒 */
export async function getAssetMonitorInfo() {
  return request(`${API_SMB}/user/page_quality_info`);
}

/** 当前企业用户资产监控提醒点击 */
export async function setAssetMonitor() {
  return request(`${API_SMB}/user/page_quality_info`, {
    method: 'POST',
  });
}

/** 获取短信验证码 */
export async function getCaptcha(params: { phone: string; code_type: SmsCodeType }) {
  return request<ResponseStructure>(`${API_SMB}/user/get_remind_captcha`, {
    method: 'GET',
    params,
  });
}

/** 同意协议 */
export async function agreeProtocol () {
  return request<ResponseStructure>(`${API_SMB}/user/agree_protocol`);
}
