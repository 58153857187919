// @ts-ignore add TS check, handle history code
import * as Sentry from '@sentry/browser';
import { notification } from 'antd';
import { checkReloadApp } from '@/utils/versionCheck';

// 从package.json中获取版本号
import { version } from './../package.json';

/** 需要忽略上报信息的正则 */
const ignoreErrorsReg = [
  /ResizeObserver loop/i, // 带这个关键字的错误是组件内部的 warning，不影响，但是报成了 error
  /Unexpected token '<'/i,
  /Event `ErrorEvent` captured as exception with message `Script error.`/i,
  /Loading\sCSS\schunk\s(\d+)\sfailed\./i,
  /Event `ErrorEvent` captured as exception with message `ResizeObserver loop completed with undelivered notifications.`/i,
];

/** 是否需要忽略此信息上报 */
export const isIgnoreError = (msg: string = '') => {
  return ignoreErrorsReg.some((reg) => reg.test(msg));
};

// fix 接口并行时出现多个 notification
notification.config({
  maxCount: 1,
});

/** 初始化 Sentry 监控 */
Sentry.init({
  dsn: 'https://0ce5d43ca8754ecdbbe310578451cb6a@o1373600.ingest.sentry.io/4504643498606592',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, // 采样率，范围在 0 ~ 1 之间，1 表示全部采样
  // 白名单，仅在某些域名下才生效
  allowUrls: [/^http(s|):\/\/luban-plus\.bluemediagroup\.cn\/.*$/],
  // 错误过滤
  ignoreErrors: [
    /Non-Error promise rejection captured with keys: errorFields, outOfDate, values/i,
    /Unexpected token '<'/i,
    /Objects are not valid as a React child/i,
    /Event `ErrorEvent` captured as exception with message `Script error.`/i,
    /Loading\sCSS\schunk\s(\d+)\sfailed\./i,
    /Event `ErrorEvent` captured as exception with message `ResizeObserver loop completed with undelivered notifications.`/i,
  ],
  // 发送错误前可进行劫持
  // @ts-ignore add TS check, handle history code
  beforeSend(
    event: {
      exception: { values: { value: string }[] };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tags: any;
      request: {
        headers: { Referer: string };
        url: string;
      };
    },
    hint,
  ) {
    // 报错信息中添加版本号
    event.tags = event?.tags || {};
    event.tags.version = version;

    const errMsg = event?.exception?.values?.[0]?.value;

    const messages = [
      'Non-Error exception captured',
      'Object captured as promise rejection with keys: errorFields, outOfDate, values',
      'Object captured as exception with keys: errorFields, outOfDate, values',
      'Object captured as promise rejection with keys: data, msg, status',
      'Non-Error promise rejection captured with keys: errorFields, outOfDate, values',
      'Objects are not valid as a React child',
      'NotFoundError: The object can not be found here',
    ];

    const isNonErrorException = messages.some(
      (message) =>
        errMsg?.startsWith(message) ||
        // @ts-ignore
        hint?.originalException?.message?.startsWith(message),
    );

    if (isNonErrorException) {
      return null;
    }

    if (!errMsg) {
      return null;
    }

    // 1. 过滤 非生产上报
    const patt = /^http(s|):\/\/luban-plus\.bluemediagroup\.cn\/.*$/;
    if (
      !patt.test(event?.request?.url || '' || event?.request?.headers?.Referer)
    ) {
      return null;
    }

    // 2. 过滤 降噪错误
    if (isIgnoreError(errMsg)) {
      return null;
    }

    return event;
  },
});

/** 监听并上报全局的 unhandledrejection 事件 */
window.addEventListener('unhandledrejection', (event) => {
  Sentry?.captureException(event.reason, {
    extra: { type: 'unhandledrejection' },
  });
});

checkReloadApp();
