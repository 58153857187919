import React, { useEffect, useState } from 'react';
import { Form, Input, Skeleton } from 'antd';

import Message from '@/pages/User/Message';
import { successMessage } from '@/utils/config';

import XModal from '@/components/XModal';
import {
  getIdentificationInfo,
  submitIdentificationInfo,
} from '@/services/realNameIdentification';

import { IDENTIFICATION_STATUS, DEFAULT_IDENTITY_MESSAGE } from './constant';

import styles from './style.less';
import { useTranslation } from 'react-i18next';

export interface RealNameIdentificationProps {
  /** 认证弹窗显隐 */
  visible: boolean;
  /** 认证弹窗关闭回调 */
  onCancel: () => void;
  /** 实名认证成功回调 */
  onIdentifySuccess?: () => void;
}

const RealNameIdentificationModal: React.FC<RealNameIdentificationProps> = (
  props,
) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { visible, onIdentifySuccess, onCancel } = props;
  // 认证错误信息
  const [errorMessage, setErrorMessage] = useState('');
  // 认证时loading
  const [identityLoading, setIdentityLoading] = useState(false);
  // 拉取信息时的loading
  const [fetchLoading, setFetchLoading] = useState(false);

  // 关闭弹窗时清空错误信息
  useEffect(() => {
    if (!visible) {
      setErrorMessage('');
    }
  }, [visible]);

  // 打开弹窗时获取认证信息
  useEffect(() => {
    if (visible) {
      setFetchLoading(true);
      getIdentificationInfo().then((res) => {
        // 返回的认证信息是一个数组，只取第一个
        if (res.data && res.data?.length > 0) {
          form.setFieldsValue(res.data[0]);
        }
      }).finally(() => {
        setFetchLoading(false);
      });
    }
  }, [form, visible]);

  // 提交认证信息
  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      setIdentityLoading(true);
      submitIdentificationInfo(values)
        .then((res) => {
          if (res.data?.status === IDENTIFICATION_STATUS.PASS) {
            onCancel();
            successMessage(t(DEFAULT_IDENTITY_MESSAGE.SUCCESS));
            if (onIdentifySuccess) {
              onIdentifySuccess();
            }
          } else {
            setErrorMessage(t(DEFAULT_IDENTITY_MESSAGE.FAIL));
          }
        })
        .finally(() => {
          setIdentityLoading(false);
        });
    });
  };

  return (
    <XModal
      destroyOnClose
      open={visible}
      className={styles['modal-no-padding']}
      title={t('real_name_authentication')}
      okText={t('authentication')}
      okButtonProps={{ loading: identityLoading }}
      onCancel={onCancel}
      onOk={handleFormSubmit}
    >
      <Skeleton loading={fetchLoading}>
        <Form layout="vertical" autoComplete="off" form={form} preserve={false}>
          <Form.Item
            className={styles['form-item']}
            label={t('name')}
            name="name"
            rules={[
              { required: true, message: t('name_cannot_be_empty') },
              { pattern: /^[^\s]+$/, message: t('name_cannot_contain_spaces') },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles['form-item']}
            label={t('id_number')}
            name="identity_card"
            rules={[
              { required: true, message: t('id_number_cannot_be_empty') },
              {
                pattern: /^\d{17}[\dX]$/,
                message: t('please_enter_the_correct_id_number_number'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles['form-item']}
            label={t('mobile_phone_number')}
            name="phone_number"
            rules={[
              { required: true, message: t('phone_number_cannot_be_empty') },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: t('please_enter_the_correct_formatted_phone_number'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
        {/* Message组件有内联样式只能通过传递style进行覆盖 */}
        {errorMessage && (
          <Message style={{ marginBottom: 0 }} content={errorMessage} />
        )}
      </Skeleton>
    </XModal>
  );
};

export default RealNameIdentificationModal;
